import React, { useState, useEffect } from 'react';

import Layout from '../../../components/layout';
import Content from '../../../components/licensed-in/content-container';

export default () => {
  const [licensedInOpened, setLicensedInOpened] = useState(false);
  const [getAQuoteOpened, setGetAQuoteOpened] = useState(false);

  const props = {
    licensedInOpened,
    setLicensedInOpened,
    getAQuoteOpened,
    setGetAQuoteOpened
  };

  return (
    <Layout {...props} activePage='licensed-in' id='licensed-in-page'>
      <div className='licensed-in-page-container'>
        <Content title='SECURE INSURANCE IN ARIZONA' activeCard='arizona' image={'/arizona-bg.jpg'}>
          <p>
            Apollo Insurance proudly works with a number of providers for our clients in Arizona, such as Signa and Blue Cross Blue Shield. Cigna is dedicated
            to partnering with consumers to create sustainable solutions for the future. Blue Cross Blue Shield of Arizona has ACA plans available for every
            type of customer. We also work closely with Aliera, National General, and United Healthcare to bring the full range of plans to our clients. Apollo
            Insurance is determined to provide affordable health insurance for all of our Arizona customers. We pay careful attention to rising premiums and
            listen to your concerns so that we can find the best insurance for you.
          </p>
          <p>
            Whether you’re searching for health insurance, dental plans, life insurance, or financial planning, Apollo Insurance can help you prepare for your
            future. As experts in health insurance, we help you keep up with the rapid changes in the industry. Trust us to take the guesswork out of health
            insurance plans – contact us today!
          </p>
          <p>
            We offer individual, family, disability, vision, dental, accident, critical illness, and gap insurance plans. With the increase in deductibles and
            out of pocket expenses of health insurance shifting to the consumer, gap insurance plans have become the most important and popular plan we sell
            today.
          </p>
        </Content>
      </div>
    </Layout>
  );
};
